import type { TrpcRouterInput } from '@chatai/admin-backend/src/router'
import { zUpdateConversionPostbackConfigsInput } from '@chatai/admin-backend/src/router/appConfig/updateConversionPostbackConfigs/input'
import { canManageAppConfig } from '@chatai/admin-backend/src/utils/can'
import { deepMap } from '@chatai/shared/src/deepMap'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const ConversionPostbackConfigsPage = withPageWrapper({
  authorizedOnly: true,
  useQuery: () => {
    return trpc.getAppConfig.useQuery()
  },
  setProps: ({ queryResult }) => ({
    appConfig: queryResult.data.appConfig,
  }),
  checkAccess: ({ ctx }) => canManageAppConfig(ctx.me),
  title: 'Postback Configs',
})(({ appConfig }) => {
  const updateConversionPostbackConfigs = trpc.updateConversionPostbackConfigs.useMutation()
  const trpcUtils = trpc.useContext()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      conversionPostbackConfigs: appConfig.conversionPostbackConfigs,
    },
    validationSchema: zUpdateConversionPostbackConfigsInput,
    onSubmit: async (values) => {
      const result = await updateConversionPostbackConfigs.mutateAsync(values)
      trpcUtils.getAppConfig.setData(undefined, result)
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'Postback configs updated',
  })

  const values = formik.values

  const setAllFieldsTouched = (values: any) => {
    deepMap(values, ({ path, value }) => {
      void formik.setFieldTouched(path, true, false)
      return value
    })
  }

  const addConversionPostbackConfig = () => {
    void formik
      .setFieldValue('conversionPostbackConfigs', [
        ...values.conversionPostbackConfigs,
        {
          postbackUrlInstall: '',
          postbackUrlTrial: '',
          postbackUrlPurchase: '',
          searchParamName: '',
          searchParamValue: '',
        } as TrpcRouterInput['updateConversionPostbackConfigs']['conversionPostbackConfigs'][number],
      ])
      .then((values) => {
        setAllFieldsTouched(values)
      })
  }

  const removeConversionPostbackConfig = (index: number) => {
    void formik.setFieldValue(
      'conversionPostbackConfigs',
      values.conversionPostbackConfigs.filter((_, i) => i !== index)
    )
  }

  return (
    <Segment title="Postback Configs" helpId="postback-configs">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <FormItems>
            <div className={css.items}>
              {values.conversionPostbackConfigs.map((conversionPostbackConfig, index) => (
                <div className={css.item} key={index}>
                  <FormItems>
                    <Input
                      formik={formik}
                      name={`conversionPostbackConfigs.${index}.searchParamName`}
                      label="Search Param Name"
                      hint={`When onelink url has search param with this name "${conversionPostbackConfig.searchParamName}"`}
                    />
                    <Input
                      formik={formik}
                      name={`conversionPostbackConfigs.${index}.searchParamValue`}
                      label="Search Param Value"
                      hint={`And its value equals to "${conversionPostbackConfig.searchParamValue}"`}
                    />
                    <Textarea
                      formik={formik}
                      name={`conversionPostbackConfigs.${index}.postbackUrlInstall`}
                      label="Postback Url On Install"
                      hint={`Send postback to this url. Macroses example. Onelink url: "https://onelink.com?utm_source=MySource&utm_content=MyContent". Postback config url: "https://postback.com?sourcex={{utm_source}}&contenty={{utm_content}}". Real postback url: "https://postback.com?sourcex=MySource&contenty=MyContent"`}
                    />
                    <Textarea
                      formik={formik}
                      name={`conversionPostbackConfigs.${index}.postbackUrlTrial`}
                      label="Postback Url On Trial"
                    />
                    <Textarea
                      formik={formik}
                      name={`conversionPostbackConfigs.${index}.postbackUrlPurchase`}
                      label="Postback Url On Purchase"
                    />

                    <Button
                      color="red"
                      type="button"
                      onClick={() => {
                        removeConversionPostbackConfig(index)
                      }}
                    >
                      Remove Postback Config
                    </Button>
                  </FormItems>
                </div>
              ))}
            </div>
            <Button
              type="button"
              onClick={() => {
                addConversionPostbackConfig()
              }}
            >
              {/* show this when user has removed all friends from the list */}
              Add Posback Config
            </Button>
          </FormItems>
          <Alert {...alertProps} />
          <Button type="submit" {...buttonProps}>
            Save
          </Button>
        </FormItems>
      </form>
    </Segment>
  )
})
