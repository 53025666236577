import { z } from 'zod'

export const zConversionPostbackConfig = z.object({
  searchParamName: z.string().min(1),
  searchParamValue: z.string().min(1),
  postbackUrlInstall: z.union([z.string().url().min(1), z.literal(''), z.undefined()]),
  postbackUrlPurchase: z.union([z.string().url().min(1), z.literal(''), z.undefined()]),
  postbackUrlTrial: z.union([z.string().url().min(1), z.literal(''), z.undefined()]),
})
export type ConversionPostbackConfig = z.infer<typeof zConversionPostbackConfig>
